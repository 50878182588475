@use "@angular/material" as mat;

@forward "./tailwind";

@use "./palette" as palette;

@include mat.core();

$erodrop-primary: mat.define-palette(palette.$primary, 500);
$erodrop-accent: mat.define-palette(palette.$accent);
$erodrop-warn: mat.define-palette(palette.$warn);

$erodrop-theme: mat.define-light-theme(
	(
		color: (
			primary: $erodrop-primary,
			accent: $erodrop-accent,
			warn: $erodrop-warn,
		),
	)
);

@include mat.all-component-themes($erodrop-theme);

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	color: var(--gray-900);
}

.mdc-button {
	font-size: 0.785rem !important;
	padding: 1rem !important;
}

.mat-mdc-form-field-hint {
	font-size: 0.625rem !important;
}

.link {
	font-size: 0.785rem !important;
	@apply inline-flex items-center text-gray-700 cursor-pointer #{!important};
}

.error {
	@apply py-2 text-red-100 #{!important};
}

.info {
	@apply py-2 text-gray-700 #{!important};
}

.mat-mdc-button.mat-primary {
	@apply bg-purple-900 px-4 text-white-900 hover:bg-purple-400 #{!important};
}

.mat-mdc-button.mat-secondry {
	@apply bg-purple-400 px-4 text-white-900 hover:bg-purple-900 #{!important};
}

.mat-mdc-dialog-container {
	@apply w-3/4 bg-purple-400 m-auto #{!important};

	.mat-mdc-dialog-title {
		@apply text-purple-900 text-2xl #{!important};
	}

	.mat-mdc-dialog-content {
		.qurator-text {
			@apply pt-2 pb-3 text-gray-600 italic #{!important};
		}

		.qurator-text-bold {
			@apply text-lg pt-2 pb-3 text-gray-600 #{!important};
		}

		.mat-mdc-form-field {
			@apply w-full;
		}

		.field-wrapper {
			@apply flex flex-col;
		}

		.field-wrapper-2-col {
			@apply flex flex-row;
		}
	}

	.mat-mdc-dialog-actions {
		@apply p-0 flex-col justify-start items-start #{!important};
		.button-wrapper {
			@apply flex-col ml-4 mr-4 mt-2 mb-2;
		}
	}
}

.mat-mdc-tab-body-content {
	@apply border-t border-gray-200 pt-2 pb-4 #{!important};
}

.mdc-snackbar__surface {
	padding: 0 !important;
	background-color: transparent !important;
}

.mat-mdc-snack-bar-label {
	padding: 0 !important;
}

.mat-expansion-panel {
	@apply mb-2 shadow-md #{!important};
	.mat-expansion-panel-header {
		.mat-content {
			.mat-expansion-panel-header-title {
				h5 {
					@apply text-xl font-bold text-purple-400 #{!important};
				}
			}
		}
	}
	.panel-content {
		p {
			@apply text-sm mb-1 text-gray-900 #{!important};
		}
	}
}
